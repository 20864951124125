<template>
    <div class="pd">
        <div class="panel" v-if="form">
            <div class="header">Add new schedule</div>
            <div class="frames">
                <div class="frame" v-if="frameset.frames" v-for="frame in frameset.frames.data"
                     :class="{active:frame_id === frame.id}" @click="frame_id = frame.id">
                    <div class="frame-color" :style="{background:frame.attributes.color}"></div>
                    <div class="frame-name">
                        {{ frame.attributes.name }}
                    </div>
                </div>
            </div>
            <sc-form :form="form" @submit="put()">

                <sc-form-field :form="form" field="playlist_id" name="Playlist">
                    <nr-select :form="form" field="playlist_id"
                               :options="{resource:'playlists?amount=10000'}"></nr-select>
                </sc-form-field>

                <div class="dates">
                    <sc-form-field class="date" :form="form" field="startDate" name="Start date"
                                   v-if="form.fields.recurrencePattern === 'WEEKLY'">
                        <datepicker v-model="startDate" format="DD-MM-YYYY"></datepicker>
                    </sc-form-field>
                    <sc-form-field class="date" :form="form" field="endDate" name="End date"
                                   v-if="form.fields.recurrencePattern === 'WEEKLY'">
                        <datepicker v-model="endDate" format="DD-MM-YYYY"></datepicker>
                    </sc-form-field>
                </div>

                <div class="dates">
                    <sc-form-field class="date" :form="form" field="startTime" name="Start time">
                        <template-time :time.sync="form.fields.startTime"></template-time>
                    </sc-form-field>
                    <sc-form-field class="date" :form="form" field="endTime" name="End time">
                        <template-time :time.sync="form.fields.endTime"></template-time>
                    </sc-form-field>
                </div>

                <sc-form-field :form="form" field="recurrencePattern" name="Recurrence">
                    <nr-radio :value.sync="form.fields.recurrencePattern" :options="[
                      {label:'Weekly', value:'WEEKLY'},
                      {label:'Monthly', value:'MONTHLY'},
                      {label:'Yearly', value:'YEARLY'}
                      ]">
                    </nr-radio>
                </sc-form-field>

                <sc-form-field :form="form" field="weekdays" name="Weekdays"
                               v-if="form.fields.recurrencePattern === 'WEEKLY'">
                    <nr-radio :value.sync="form.fields.weekdays" :multi="true" :options="[
                      {label:'Sunday', value:'SUNDAY'},
                      {label:'Monday', value:'MONDAY'},
                      {label:'Tuesday', value:'TUESDAY'},
                      {label:'Wednesday', value:'WEDNESDAY'},
                      {label:'Thursday', value:'THURSDAY'},
                      {label:'Friday', value:'FRIDAY'},
                      {label:'Saturday', value:'SATURDAY'},
                      ]">
                    </nr-radio>
                </sc-form-field>

                <sc-form-field class="date" :form="form" field="startDate"
                               :name="(form.fields.recurrencePattern === 'MONTHLY')? 'Starting from' : 'On the'"
                               v-if="form.fields.recurrencePattern === 'MONTHLY' || form.fields.recurrencePattern === 'YEARLY'">
                    <datepicker v-model="startDate" format="DD-MM-YYYY"></datepicker>
                </sc-form-field>

                <sc-form-field :form="form" field="monthPeriod" name="Every"
                               v-if="form.fields.recurrencePattern === 'MONTHLY'">
                    <select v-model="form.fields.monthPeriod">
                        <option :value="1">1 Month</option>
                        <option :value="2">2 Months</option>
                        <option :value="3">3 Months</option>
                        <option :value="4">4 Months</option>
                        <option :value="6">6 Months</option>
                    </select>
                </sc-form-field>

                <template #buttons>
                    <div style="text-align: center">
                        <sc-button icon="save" @click="put">Add schedule</sc-button>
                    </div>
                </template>

            </sc-form>
        </div>
    </div>
</template>

<script>
import datepicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment-timezone';
import TemplateTime from "@/views/app/templates/components/template-time";

export default {
  name: "schedule-frame-form",

  components: {
    TemplateTime,
    datepicker
  },

  props: {
    frameset: {}
  },

  data() {
    return {
      frame_id: undefined,
      form: undefined,
      startDate: new Date(),
      endDate: '',
    }
  },

  mounted() {
    if (this.frameset.frames) {
      this.frame_id = this.frameset.frames.data[0].id
    }

    this.form = this.$sform.createForm({
      url: `channels/schedule/${this.frame_id}`,
      fields: {
        playlist_id: {
          name: 'Playlist',
          component: 'nr-select',
          options: {
            resource: 'playlists?amount=1000',
            search_query: 'name'
          }
        },
        startDate: {},
        endDate: {},
        startTime: '00:00:00',
        endTime: '23:59:59',
        recurrencePattern: 'WEEKLY',
        weekdays: {
          default: ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']
        },
        monthPeriod: {
          default: 1
        }
      }
    })
  },

  methods: {

    put() {
      this.form.urls.put = `channels/schedule/${this.frame_id}`;
      this.form.fields.startDate = moment(this.startDate).format('YYYY-MM-DD');
      this.form.fields.endDate = (this.endDate) ? moment(this.endDate).format('YYYY-MM-DD') : '';
      this.form.put()
        .then(res => {
          this.$emit('refresh')
        })
    }

  }
}
</script>

<style scoped lang="scss">
.frames {
    display: flex;
    margin: 1em 32px 0;

    .frame {
        padding: 0.5em;
        border: 2px solid #ccc;
        border-radius: 0.3em;
        display: flex;
        align-items: center;
        margin-right: 1em;
        cursor: pointer;

        &:hover {
            background: #fafafa;
        }

        &.active {
            border: 2px solid $color-primary;
        }

        .frame-color {
            width: 1em;
            height: 1em;
            border-radius: 0.2em;
            margin-right: 0.4em;
        }

        .frame-name {
            font-size: 0.9em;
        }
    }
}

.dates {
    display: flex;
    flex-wrap: wrap;

    .date {
        flex: 1;
    }

    .time-picker {
        justify-content: flex-start;
    }
}
</style>
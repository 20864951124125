<template>
    <div class="page">
        <div class="page-header pd">
            <sc-button icon="chevron-left" @click="$router.push(`/app/${resource.route}/${item.id}/edit`)">Back to channel</sc-button>
        </div>
        <div v-if="item" class="pd">
            <div class="schedule-header">
                Channel Schedule: {{ item.attributes.name }}
            </div>
        </div>
        <div class="top-section">
            <div class="schedule-frames" v-if="frameset">
                <frames-canvas :render-only="true" :form-frames="frames"
                               :height="frameset.attributes.height"
                               :width="frameset.attributes.width"
                               class="canvas"/>
            </div>
            <schedule-frame-form style="flex:1;" v-if="frameset" :frameset="frameset"
                                 @refresh="fetch"></schedule-frame-form>
        </div>

        <schedule-frame v-for="frame in frames" :key="frame.id" :frame="frame" @refresh="fetch"></schedule-frame>
    </div>
</template>

<script>
import ScheduleFrame from "@/views/app/channels/components/schedule-frame";
import ScheduleFrameForm from "@/views/app/channels/components/schedule-frame-form";
import FramesCanvas from "../admin/framesets/components/frames-canvas";

export default {
  name: "edit",
  components: {FramesCanvas, ScheduleFrameForm, ScheduleFrame},
  props: {
    passed: {
      required: true,
    }
  },

  data() {
    return {
      item: null,
      frames: [],
      frameset: null
    }
  },

  computed: {
    resource() {
      return this.passed
    }
  },

  mounted() {
    this.fetch();
  },

  methods: {

    fetch() {
      this.$talker.api(`${this.resource.api}/${this.$route.params[this.resource.route_param]}`)
        .then(res => {
          this.item = res.data.data;
          if (res.data.data.frameset) {
            this.frameset = res.data.data.frameset.data
          }
          if (this.frameset && this.frameset.frames) {
            this.frames = this.frameset.frames.data;
          }
        });
    }

  }

}
</script>

<style scoped lang="scss">
.schedule-header {
    font-weight: 600;
    font-size: 1.2em;
}

.label {
    color: $color-primary;
    font-weight: 700;
}

.value {
    padding: 0.5em;
}

.top-section {
    display: flex;

    .schedule-frames {
        flex: 1;
        min-height: 300px;
        background: #fff;
        margin-left: 32px;
        border-radius: 0.3em;
        margin-top: 28px;
        box-shadow: $shading;
        text-align: center;
        padding: 1em;
    }
}
</style>
<template>
    <div>
        <sc-table :table="table">

            <template #column-schedule="{row}">
                <sc-table-cell :table="table" field="schedule">
                    {{ row.attributes.startDate }} - <span v-if="row.attributes.endDate">{{
                        row.attributes.endDate
                    }}</span><span v-else>n/a</span>
                </sc-table-cell>
            </template>

            <template #column-time="{row}">
                <sc-table-cell :table="table" field="time">
                    {{ row.attributes.startTime }} - {{ row.attributes.endTime }}
                </sc-table-cell>
            </template>

            <template #column-recurrence="{row}">
                <sc-table-cell :table="table" field="recurrence">
                    {{ row.attributes.recurrencePattern }}
                    <div class="days" v-if="row.attributes.recurrencePattern === 'WEEKLY'">
                        <div class="day" v-for="day in days" :class="{active:row.attributes.weekdays.includes(day)}">
                            {{ day.substring(1, 0) }}
                        </div>
                    </div>
                </sc-table-cell>
            </template>

            <template #column-delete="{row}">
                <sc-table-cell :table="table" field="delete" :padded="false" :clickable="false">
                    <sc-table-button-delete @destroy="destroy(row)">
                        Do you wish to delete this timeslot?
                    </sc-table-button-delete>
                </sc-table-cell>
            </template>

        </sc-table>
    </div>
</template>

<script>


export default {
  name: "schedule-frame",

  props: {
    frame: {}
  },

  data() {
    return {
      table: null,
      days: ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']
    }
  },

  watch: {
    frame: {
      handler(newVal, oldVal) {
        this.setData();
      },
      deep: true
    }
  },

  mounted() {
    this.table = this.$stable.createTable({
      name: `Existing schedule for frame ${this.frame.attributes.name}`,
      columns: {
        schedule: {},
        time: {},
        recurrence: {},
        playlist: {
          target: 'attributes.playlist.name',
          fill: true
        },
        duration: {
          target: 'attributes.playlist.prettifyDuration'
        },
        delete: {sort: false,}
      }
    })
    this.setData();
  },

  methods: {

    setData() {
      let slots = this.frame.timeslots;
      this.table.data = slots !== undefined ? [...slots.data] : []
      this.table.hasData = slots !== undefined;
      this.table.prefetch();
    },

    destroy(row) {
      this.$talker.api.delete(`timeslot/${row.id}`)
        .then(res => {
          this.$notify.success('Timeslot deleted')
          this.$emit('refresh')
        })
    }
  }
}
</script>

<style scoped lang="scss">
.days {
    display: flex;
    margin-top: 0.2em;

    .day {
        font-size: 0.9em;
        margin-right: 0.1em;
        width: 1em;
        height: 1em;
        border-radius: 100px;
        background: #aaa;
        text-align: center;
        line-height: 1em;
        color: #fff;
        padding: 0.3em;
        font-weight: 700;

        &.active {
            background: $color-primary;
        }
    }
}
</style>
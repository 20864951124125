var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('sc-table',{attrs:{"table":_vm.table},scopedSlots:_vm._u([{key:"column-schedule",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"schedule"}},[_vm._v(" "+_vm._s(row.attributes.startDate)+" - "),(row.attributes.endDate)?_c('span',[_vm._v(_vm._s(row.attributes.endDate))]):_c('span',[_vm._v("n/a")])])]}},{key:"column-time",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"time"}},[_vm._v(" "+_vm._s(row.attributes.startTime)+" - "+_vm._s(row.attributes.endTime)+" ")])]}},{key:"column-recurrence",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"recurrence"}},[_vm._v(" "+_vm._s(row.attributes.recurrencePattern)+" "),(row.attributes.recurrencePattern === 'WEEKLY')?_c('div',{staticClass:"days"},_vm._l((_vm.days),function(day){return _c('div',{staticClass:"day",class:{active:row.attributes.weekdays.includes(day)}},[_vm._v(" "+_vm._s(day.substring(1, 0))+" ")])}),0):_vm._e()])]}},{key:"column-delete",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"delete","padded":false,"clickable":false}},[_c('sc-table-button-delete',{on:{"destroy":function($event){return _vm.destroy(row)}}},[_vm._v(" Do you wish to delete this timeslot? ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }